






































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Button from '@/app/ui/components/Button/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import MultiselectV2 from '@/app/ui/components/MultiselectV2/index.vue'
import ModalConfirm from '../../components/Modals/ModalConfirm/index.vue'
import ModalSuccess from '../../components/Modals/ModalSuccess/index.vue'
import manageCourierController from '@/app/ui/controllers/ManageCourierController'
import controller from '@/app/ui/controllers/ManageCaptainController'
import { ManageCourierAdjustSaldo } from '@/domain/entities/ManageCourier'
import dayjs from 'dayjs'
import Loading from '@/app/ui/components/Loading/index.vue'
import routeController from '@/app/ui/controllers/RouteController'
import { LetterCode } from '@/domain/entities/Route'

interface IOptions {
  label: string
  value: string | number
}

interface IForm {
  courier: IOptions
  letterCodes: IOptions[]
  startAt: string
  endAt: string
  status: string
}

interface IParams {
  page: number
  perPage: number
  q: string
  sortBy: string
  driverType: string
  courierStatus: string
}

interface ManageCaptainDetail {
  id: number
  courierId: number
  letterCodes: Array<string>
  skStartDate: string
  skEndDate: string
  status: string
}

interface IValidation {
  [k: string]: unknown
}

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    DropdownSelect,
    TextInput,
    DateTimePicker,
    MultiselectV2,
    ModalConfirm,
    ModalSuccess,
    Loading,
  },
})
export default class EditManageCaptain extends Vue {
  controller = controller
  mcController = manageCourierController
  routeController = routeController
  modalConfirmVisible = false
  modalSuccessVisible = false
  captainLetterCodes: Array<string> = []
  isValidate = false

  courierList: Array<{ label: string; value: number }> = []
  letterCodeOptions: Array<IOptions> = []

  form: IForm = {
    courier: this.courierList[-1],
    letterCodes: this.letterCodeOptions,
    startAt: '',
    endAt: '',
    status: '',
  }

  parameters: IParams = {
    page: 1,
    perPage: 10,
    q: '',
    sortBy: 'ASC',
    driverType: 'KVP',
    courierStatus: '',
  }

  @Validations()
  validations(): IValidation {
    return {
      form: {
        letterCodes: { required },
        startAt: { required },
        endAt: { required },
      },
    }
  }

  created(): void {
    this.fetchData()
  }

  get courierParams(): Record<string, string | number> {
    return { ...this.parameters }
  }

  private fetchData(): void {
    this.controller.getDetail(Number(this.$route.params.id))
  }

  private fetchCourier(): void {
    this.mcController.getManageCourierListAdjustSaldo(this.courierParams)
  }

  private fetchOriginList(): void {
    this.routeController.getLetterCodes()
  }

  private onSearchOrigin(query: string): void {
    const res: Array<IOptions> = []

    if (query === '') {
      this.letterCodeOptions = this.routeController.letterCodes.map((route)=> {
        return {
          label: <string>route.lc,
          value: <string>route.lc
        }
      })
    }

    routeController.letterCodes.forEach(route => {
      if ((<string>route.lc).toUpperCase().includes(query.toUpperCase())) {
        res.push({
          label: <string>route.lc,
          value: <string>route.lc
        })
      }
    })

    this.letterCodeOptions = res
  }

  private onSubmit(): void {
    this.isValidate = true
    this.modalConfirmVisible = false

    if (!this.$v.form.$invalid) {
      const payload = {
        id: this.$route.params.id,
        letter_codes:
          this.form.letterCodes.map(item => String(item.value)),
        sk_start_date: this.form.startAt,
        sk_end_date: this.form.endAt,
      }
      controller.update(payload)
    }
  }

  private onBack(): void {
    this.$router.push(`/payroll/manage-captain/`)
  }

  private onCheckStatus(): void {
    if (this.form.startAt && this.form.endAt) {
      const beforeToday = dayjs().isBefore(dayjs(this.form.endAt))
      const afterToday = dayjs().isAfter(dayjs(this.form.startAt))
      const isStartCurrentSame = dayjs().isSame(dayjs(this.form.startAt), 'day')
      const isEndCurrentSame = dayjs().isSame(dayjs(this.form.endAt), 'day')

      if (isStartCurrentSame && isEndCurrentSame) {
        this.form.status = 'Active'
        return
      }

      if (beforeToday && afterToday) {
        this.form.status = 'Active'
      } else {
        this.form.status = 'Inactive'
      }
    } else {
      this.form.status = ''
    }
  }

  @Watch('controller.dataManageCaptain')
  setCaptainData(data: ManageCaptainDetail): void {
    this.captainLetterCodes = data.letterCodes ? data.letterCodes : []

    this.form = {
      courier: this.courierList[-1],
      letterCodes: this.letterCodeOptions,
      startAt: data.skStartDate ? String(data.skStartDate) : '',
      endAt: data.skEndDate ? String(data.skEndDate) : '',
      status: String(data.status),
    }
    this.parameters.q = data.courierId ? String(data.courierId) : ''

    this.fetchCourier()
    this.fetchOriginList()
  }

  @Watch('mcController.manageCourierAdjustSaldoData')
  setManageCourierData(data: ManageCourierAdjustSaldo[]): void {
    const result: Array<{ label: string; value: number }> = []

    data.forEach((courier: ManageCourierAdjustSaldo) => {
      const label = `[${courier.courierId}] ${courier.fullName} (${courier.announcementCourierType}) ${courier.phoneNumber} • ${courier.partnerName}`
      const value = courier.courierId

      if (label && value) {
        result.push({
          label: label,
          value: value,
        })
      }
    })

    this.courierList = result

    if (this.courierList.length !== 0) {
      const courier = this.courierList.find((courier) => courier.value === controller.dataManageCaptain.courierId)
      if (courier) {
        this.form.courier = courier
      }
    }
  }

  @Watch('routeController.letterCodes')
  setOriginData(data: LetterCode[]): void {
    this.letterCodeOptions = data.map((route) => {
      return {
        label: <string>route.lc,
        value: <string>route.lc
      }
    })
    const letterCode: IOptions[] = []

    if (this.letterCodeOptions.length !== 0) {
      this.captainLetterCodes.forEach(item => {
        const store = this.letterCodeOptions.find(opt => {
          return opt.value === item
        })

        if (store) {
          letterCode.push(store)
        }
      })
    }

    this.form.letterCodes = letterCode
  }

  @Watch('controller.isEditManageCaptainSuccess')
  setEditManageCaptainSuccess(data: boolean): void {
    if (data) {
      this.modalSuccessVisible = true
      this.controller.setEditManageCaptainSuccess(false)
    }
  }
}
